import React, {
    ReactElement,
    useState,
    useEffect,
    useMemo,
    Dispatch,
} from "react";
import Modal from "@components/shared/modal";
import CloseIcon from "@components/svg/closeIcon";
import {
    ActionTypes,
    Buyer,
    FormReducerAction,
    Operation,
} from "@hooks/useForm/types";
import { useDomainContext } from "@hooks/useDomainContext";

const TCPAText = ({
    text,
    buttonText = "submit",
    finalBuyers,
    secondRound = false,
    dispatch,
    firstRoundBuyers,
    excludeDomain,
}: {
    text: string;
    buttonText?: string;
    finalBuyers: Buyer[];
    secondRound?: boolean;
    dispatch: Dispatch<FormReducerAction>;
    firstRoundBuyers: Buyer[];
    excludeDomain?: boolean;
}) => {
    // check for changes in checked property and update the dom element
    useEffect(() => {
        const inputs = document.querySelectorAll(
            '[data-tf-element-role*="consent-opted-advertiser-input"]',
        );

        const handleCheckboxChange = (input: Element) => {
            const name = input.getAttribute("data-tf-element-role");
            const element = document.querySelector(
                `[data-tf-element-role="${name as string}"]`,
            );
            const checked = element?.getAttribute("checked");
            if (element) {
                element.setAttribute(
                    "checked",
                    checked === "true" ? "false" : "true",
                );
            }

            if (input.getAttribute("value") === "domain") {
                dispatch({
                    type: ActionTypes.SET_OPERATION,
                    payload: {
                        hasDomainConsent:
                            input.getAttribute("checked") === "true",
                    },
                });
                return;
            }

            const checkboxes = document.querySelectorAll(
                '[data-tf-element-role*="consent-opted-advertiser-input"]',
            );

            const updatedBuyers: Buyer[] = [];

            checkboxes.forEach((checkbox) => {
                const checked = checkbox?.getAttribute("checked");
                const id = checkbox?.getAttribute("value");

                const currentBuyer = firstRoundBuyers.find(
                    (buyer) => buyer.buyerId === id,
                );
                if (currentBuyer) {
                    updatedBuyers.push({
                        ...currentBuyer,
                        selected: checked === "true",
                    });
                }
            });

            dispatch({
                type: ActionTypes.SET_OPERATION,
                payload: {
                    firstRoundBuyers: updatedBuyers,
                },
            });
        };

        inputs.forEach((input) => {
            input.addEventListener("change", () => handleCheckboxChange(input));
        });

        dispatch({
            type: ActionTypes.SET_OPERATION,
            payload: {
                hasDomainConsent: true,
                firstRoundBuyers: firstRoundBuyers.map((buyer) => ({
                    ...buyer,
                    selected: true,
                })),
            },
        });

        return () => {
            inputs.forEach((input) => {
                input.removeEventListener("change", () =>
                    handleCheckboxChange(input),
                );
            });
        };
    }, []);

    if (secondRound) {
        return (
            <>
                <div
                    dangerouslySetInnerHTML={{
                        __html: text
                            ?.replace(
                                /\$\{btnText\}/g,
                                `\<span data-tf-element-role="submit-text" \>${buttonText}\<\/span\>`,
                            )
                            .replace(
                                "the following: ${buyers}",
                                "the selected providers above.",
                            ),
                    }}
                />
            </>
        );
    }

    if (excludeDomain) {
        return (
            <>
                <div
                    dangerouslySetInnerHTML={{
                        __html: text
                            ?.replace(
                                /\$\{btnText\}/g,
                                `\<span data-tf-element-role="submit-text" \>${buttonText}\<\/span\>`,
                            )
                            .replace("the following: ${buyers}", "${buyers}")
                            .replace(
                                /\$\{buyers\}/g,
                                `${firstRoundBuyers
                                    ?.map(
                                        (buyer) =>
                                            `<span data-tf-element-role="consent-advertiser-name">${
                                                buyer.displayName ??
                                                buyer.buyerName
                                            }</span>`,
                                    )
                                    .join(" ")}`,
                            ),
                    }}
                />
            </>
        );
    }

    return (
        <>
            <div
                dangerouslySetInnerHTML={{
                    __html: text
                        ?.replace(
                            /\$\{btnText\}/g,
                            `\<span data-tf-element-role="submit-text" \>${buttonText}\<\/span\>`,
                        )
                        .replace(
                            /\$\{buyers\}/g,
                            `${finalBuyers
                                ?.map(
                                    (buyer) =>
                                        `<span  style="display: inline-block"><span style="display:flex;flex-wrap:nowrap;position: relative;top: 2px;align-items: center;height:10px" data-tf-element-role="consent-opted-advertiser-name-${
                                            buyer.buyerId
                                        }"><input value="${
                                            buyer.buyerId
                                        }" type="checkbox" style="margin:0px 3px;accent-color: gray;width:10px;height:10px"  checked="true" data-tf-element-role="consent-opted-advertiser-input-${
                                            buyer.buyerId
                                        }" />${
                                            buyer.displayName ?? buyer.buyerName
                                        }</span></span>`,
                                )
                                .join(" ")}`,
                        ),
                }}
            />
        </>
    );
};

export default function TCPA({
    content,
    className,
    buttonText,
    operation,
    dispatch,
}: {
    content: string | { TCPAMessage: string }[];
    className: string;
    buttonText?: string;
    operation: Partial<Operation>;
    dispatch: Dispatch<FormReducerAction>;
}): ReactElement {
    const [showPartnerPopup, setShowPartnerPopup] = useState(false);
    const [finalPopupContent, setFinalPopupContent] = useState<string | null>(
        null,
    );
    const extractTcpaMessagesFromContent = (arr: { TCPAMessage: string }[]) => {
        const arrayOfStrings = arr.map((el) => el.TCPAMessage);
        const tcpaArrayAfterRemoveDuplicate = new Set(arrayOfStrings);
        return [...tcpaArrayAfterRemoveDuplicate];
    };

    const { domain } = useDomainContext();
    useEffect(() => {
        const partnersEl = document.getElementById("tcpa-Modal");
        const popupContent = partnersEl?.getElementsByTagName("span")[0];
        if (popupContent) {
            setFinalPopupContent(popupContent?.innerHTML.trim());
        }

        partnersEl?.addEventListener("click", () => {
            setShowPartnerPopup(true);
        });

        if (
            operation &&
            operation.key === "FETCH_BUYERS" &&
            operation.loadingBuyers
        ) {
            window.cancelledOperations = window.cancelledOperations ?? [];
            window.cancelledOperations.push(operation.operationId as string);
        }
    }, []);

    const secondRound = useMemo(() => {
        return operation?.key === "ROUND_TWO";
    }, [operation?.key]);

    const excludeDomain = useMemo(() => {
        return (
            operation?.firstRoundBuyers?.length === 1 &&
            operation?.firstRoundBuyers?.[0].isNoble
        );
    }, [operation?.firstRoundBuyers]);

    const finalBuyers = useMemo(() => {
        return [
            ...((secondRound
                ? operation?.secondRoundBuyers?.filter(
                      (buyer) => buyer.selected,
                  ) ?? []
                : operation?.firstRoundBuyers) ?? []),

            ...(!excludeDomain
                ? [
                      {
                          displayName: domain.niceName ?? domain.name,
                          buyerName: domain.name,
                          buyerId: "domain",
                          logo: "",
                      } as Buyer,
                  ]
                : []),
        ];
    }, [operation?.firstRoundBuyers, operation?.secondRoundBuyers]);

    return (
        <>
            <input type="hidden" id="leadid_tcpa_disclosure"></input>

            <label
                htmlFor="leadid_tcpa_disclosure"
                style={{
                    height: "fit-content",
                    fontSize: "9px",
                    color: "#242626",
                }}
                data-tf-element-role="consent-language"
                className={`font-light leading-3 block mt-5 mb-5 ${
                    className ?? ""
                }`}
            >
                {Array.isArray(content) && content.length ? (
                    <>
                        {extractTcpaMessagesFromContent(content).map(
                            (tcpa, index) => (
                                <TCPAText
                                    dispatch={dispatch}
                                    text={tcpa}
                                    key={index}
                                    finalBuyers={finalBuyers}
                                    buttonText={buttonText}
                                    secondRound={secondRound}
                                    firstRoundBuyers={
                                        operation?.firstRoundBuyers ?? []
                                    }
                                    excludeDomain={excludeDomain}
                                />
                            ),
                        )}
                    </>
                ) : (
                    <TCPAText
                        dispatch={dispatch}
                        text={content as string}
                        finalBuyers={finalBuyers}
                        buttonText={buttonText}
                        secondRound={secondRound}
                        firstRoundBuyers={operation?.firstRoundBuyers ?? []}
                        excludeDomain={excludeDomain}
                    />
                )}
            </label>
            <Modal
                onClose={() => {
                    setShowPartnerPopup(false);
                }}
                active={showPartnerPopup && !!finalPopupContent}
                closeIcon={<CloseIcon />}
                backdropClosable
                modalStyles={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "100%",
                }}
                modalClassName="h-[90vh] rounded"
                bodyStyles={{ overflowY: "scroll" }}
                width={900}
                header="Our Partners"
            >
                <div
                    className="pb-5 lg:px-9 px-5"
                    dangerouslySetInnerHTML={{
                        __html: finalPopupContent as string,
                    }}
                />
            </Modal>
        </>
    );
}
