import { ITextBlock, Operation, UseFormHook } from "@hooks/useForm/types";
import { FormProps } from "..";
import {
    Category,
    ExpandableFieldMeta,
    Field,
    Image,
    Step,
} from "@lib/shared/types";
import { DomainForm } from "src/api";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useFormContext } from "@hooks/useFormContext";
import useWindowSize from "@hooks/useWindowSize";
import { useDomainContext } from "@hooks/useDomainContext";
import {
    getSecondServiceCategoryIcon,
    replacePlaceholders,
} from "@lib/sharedUtils";
import styles from "../styles.module.scss";
import ArrowRightIcon from "@components/svg/arrowRightIcon";
import Loader from "@components/shared/loader";
import { sendTrackingTime as sendVisitMeta } from "src/api/trackingTime";
import {
    COMMON_FIELDS_CODENAME,
    SHARED_FIELDS_CODENAME,
} from "@lib/shared/config";
import S3Icon from "@components/shared/s3Icon";
const AnswerSummary = dynamic(() => import("../answerSummary"));
import Tooltip from "rc-tooltip";
import TooltipIcon from "@components/svg/tooltipIcon";
import MissingFieldsHeadline from "../piiCompletion/MissingFieldsHeadline";
import SmsTCPA from "../TCPA/smsTCPA";
import OtherFieldsHeadline from "../piiCompletion/OtherFieldsHeadline";
import dynamic from "next/dynamic";
import { FormField } from "../formField";
import { decodeFieldsFromText } from "@hooks/useForm/utils";

interface FormStepProps
    extends FormProps,
        Pick<
            UseFormHook,
            | "handleFieldBlur"
            | "handleFieldChange"
            | "currentStep"
            | "isLoading"
            | "formError"
            | "fieldsData"
        > {
    step: Step;
    index: number;
    steps: Step[];
    visitedSteps: {};
    hideStepTitle?: boolean;
    hideStepDescription?: boolean;
    forceStepTitle?: boolean;
    formSubmitted?: boolean;
    formId: number;
    stepStatus: string;
    checkIfStepHasOneField: (fieldsData: {
        [x: string]: {
            value: string;
            valid: boolean;
            errorMessage: string;
        };
    }) => boolean;
    overrideStepTitle?: string | undefined;
    overrideStepDescription?: string | undefined;
    hideOffers?: boolean;
    isSecondServiceForm?: boolean;
    isFormInsideOffersModal: boolean;
    formButtonClassName?: string;
    form: DomainForm;
    textBlocks: ITextBlock[];
    updateSecondServices?: (slug: string) => void;
    tags?: { [x: string]: { slug: string; active: boolean } };
    activeSecondServices?: string[];
    secondServiceLoading?: boolean;
    showPiiCompletionOtherFields: boolean;
    setShowPiiCompletionOtherFields: (value: boolean) => void;
    showFormModal?: boolean;
    operation: Operation | null;
    ignoreTextBlocks?: boolean;
}

export const FormStep = (props: FormStepProps): ReactElement => {
    const { setSkipToThankYou } = useFormContext();

    const { width } = useWindowSize();
    const [isSubmitStepButtonBlocked, setSubmitStepButtonBlocked] =
        useState(true);
    const {
        step,
        index,
        colors,
        classes,
        handleFieldBlur,
        handleFieldChange,
        currentStep,
        isLoading,
        formError,
        fieldsData,
        form,
        customMainTitle,
        steps,
        visitedSteps,
        hideStepTitle,
        hideStepDescription,
        forceStepTitle,
        checkIfStepHasOneField,
        formSubmitted,
        formId,
        overrideStepTitle,
        overrideStepDescription,
        stepStatus,
        formErrors,
        isSecondServiceForm,
        formButtonClassName,
        textBlocks,
        updateSecondServices,
        tags,
        isPiiCompletionForm,
        showPiiCompletionOtherFields,
        setShowPiiCompletionOtherFields,
        searchableMultiSelect,
        showFormModal,
        answerSummaryConfigs,
        isFormInsideOffersModal,
        operation,
        ignoreTextBlocks,
    } = props;

    const { formattedTitle, visitDetails, category, piiCompletionData } =
        useDomainContext();
    const [buttonDisabled, setButtonDisabled] = useState({
        getQuote: false,
        noThankYou: false,
    });
    const isSecondServiceSelected = useMemo(() => {
        if (tags) {
            const tagsKeys = Object.keys(tags);
            let tagsSelected = false;
            tagsKeys.forEach((tag) => {
                tagsSelected = tagsSelected || tags[tag].active;
            });
            return tagsSelected;
        }
        return false;
    }, [tags]);
    const isLastStep: boolean = useMemo(() => {
        return currentStep === steps?.length - 1;
    }, [currentStep, steps]);

    const stepTitle = replacePlaceholders(step.title, {
        region: visitDetails.region,
        city: visitDetails?.city,
        category: category?.label ?? category?.name,
    });

    useEffect(() => {
        setSubmitStepButtonBlocked(
            (isSecondServiceForm && !isSecondServiceSelected) ||
                buttonDisabled.getQuote ||
                isLoading,
        );
    }, [
        isSecondServiceForm,
        isSecondServiceSelected,
        buttonDisabled,
        isLoading,
    ]);

    const buttonIsLoading = useMemo(() => {
        return (
            isLoading ||
            (isLastStep && formSubmitted) ||
            (isLastStep && operation?.loadingBuyers)
        );
    }, [isLoading, isLastStep, formSubmitted, operation?.loadingBuyers]);

    // const showLogos = useMemo(() => {
    //     return !!operation?.firstRoundBuyers?.length && isLastStep;
    // }, [isLastStep, operation?.firstRoundBuyers]);

    const checkIfShowNextButton = () => {
        if (
            checkIfStepHasOneField(fieldsData) ||
            (form.testConfig?.config?.mergeFirstInput && !showFormModal)
        ) {
            return null;
        }

        const firstStepButtonText = textBlocks?.find(
            (item) =>
                item.position === "form-inside-exit-modal-submit-button-text",
        );
        const secondStepButtonText = textBlocks?.find(
            (item) =>
                item.position ===
                "form-inside-exit-modal-submit-button-text-second-step",
        );
        const buttonText =
            currentStep === 0 ? firstStepButtonText : secondStepButtonText;

        return (
            <button
                style={{
                    backgroundColor:
                        colors.primaryColor ||
                        "var(--global-form-buttons-background-color)",
                }}
                className={`${styles["submit-button"]} ${
                    classes?.formButtonClassName ?? ""
                } ${
                    isLastStep ? classes?.formSubmitButtonClassName ?? "" : ""
                } ${isLastStep ? "final-step" : ""} ${
                    currentStep === 0 && isSubmitStepButtonBlocked // used for the first step to disable the submit button but without showing it.
                        ? `${styles["disable-without-opacity"]}` // because marketing team is claiming that the disabled button lowers the conversion!!
                        : ""
                }`}
                data-tf-element-role={isLastStep ? "submit" : undefined}
                type="submit"
                id="frmMainBtn"
                disabled={
                    isSubmitStepButtonBlocked ||
                    buttonIsLoading ||
                    (!!operation?.firstRoundBuyers?.length &&
                        !operation?.firstRoundBuyers?.filter(
                            (buyer) => buyer.selected,
                        )?.length &&
                        isLastStep)
                }
            >
                <div
                    className={
                        !isSecondServiceForm ? styles["progressBtn"] : ""
                    }
                ></div>
                {buttonIsLoading ? (
                    <>
                        <Loader size={30} />{" "}
                        {isLastStep ? (
                            <span className={styles["submittingLabel"]}>
                                {operation?.loadingBuyers
                                    ? "Finding the Best Match"
                                    : "Submitting"}
                            </span>
                        ) : (
                            ""
                        )}
                    </>
                ) : (
                    <>
                        {isFormInsideOffersModal && buttonText ? (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: buttonText.text,
                                }}
                            />
                        ) : (
                            <span>{step.buttonText ?? "Next"}</span>
                        )}
                        {currentStep === 0 && (
                            <span className={`${styles["arrow"]}`}>
                                <ArrowRightIcon />
                            </span>
                        )}
                    </>
                )}
            </button>
        );
    };

    const showMergedButton = () => {
        if (form.testConfig?.config?.mergeFirstInput && !showFormModal) {
            return (
                <button
                    style={{
                        backgroundColor:
                            colors.primaryColor ||
                            "var(--global-form-buttons-background-color)",
                    }}
                    className={`${styles["first-step-button-merged"]}  ${
                        classes?.formButtonClassName ?? ""
                    }`}
                    type="submit"
                    id="frmMainBtn"
                    disabled={isSubmitStepButtonBlocked}
                >
                    <div className="progressBtn"></div>
                    {isLoading ? (
                        <>
                            <Loader size={30} />{" "}
                            {isLastStep ? (
                                <span className={styles["submittingLabel"]}>
                                    Submitting
                                </span>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        <>
                            <span>{step.buttonText ?? "Next"}</span>
                            {currentStep === 0 && (
                                <span className={`${styles["arrow"]}`}>
                                    <ArrowRightIcon />
                                </span>
                            )}
                        </>
                    )}
                </button>
            );
        }
    };

    const getTitle = () => {
        if (formattedTitle && formattedTitle.length) {
            return formattedTitle;
        } else if (
            (!ignoreTextBlocks &&
                textBlocks &&
                textBlocks.length &&
                textBlocks.find((item) => item.position === "form_title")) ||
            (isFormInsideOffersModal &&
                textBlocks.find(
                    (item) =>
                        item.position ===
                        "form-inside-exit-modal-first-step-title",
                ))
        ) {
            if (isFormInsideOffersModal) {
                return replacePlaceholders(
                    textBlocks.find(
                        (item) =>
                            item.position ===
                            "form-inside-exit-modal-first-step-title",
                    )?.text as string,
                    {
                        category: category?.name,
                        region: visitDetails.region,
                        city: visitDetails?.city,
                    },
                );
            }
            return replacePlaceholders(
                textBlocks.find((item) => item.position === "form_title")
                    ?.text as string,
                {
                    category: category?.name,
                    region: visitDetails.region,
                    city: visitDetails?.city,
                },
            );
        } else {
            return overrideStepTitle
                ? overrideStepTitle
                : forceStepTitle
                ? stepTitle
                : customMainTitle
                ? customMainTitle
                : stepTitle;
        }
    };
    const getDescription = () => {
        const textBlockDescription =
            !ignoreTextBlocks &&
            index === 0 &&
            textBlocks &&
            textBlocks.length &&
            (textBlocks.find((item) => item.position === "form_description")
                ?.text as string);

        const description =
            textBlockDescription || overrideStepDescription || step.description;

        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: decodeFieldsFromText(description, fieldsData),
                }}
            ></div>
        );
    };
    const getTitleSource = () => {
        if (formattedTitle && formattedTitle.length) {
            return "url";
        } else if (
            textBlocks &&
            textBlocks.length &&
            textBlocks.find(
                (item) =>
                    item.position === "form_title" ||
                    item.position ===
                        "form-inside-exit-modal-first-step-title" ||
                    item.position ===
                        "form-inside-exit-modal-second-step-title",
            )
        ) {
            return "text_block";
        } else if (customMainTitle || overrideStepTitle) {
            return "code";
        } else {
            return "form";
        }
    };

    useEffect(() => {
        if (index === 0 && !isSecondServiceForm && !isPiiCompletionForm) {
            void sendVisitMeta({
                event: "formTitle",
                eventValue: getTitle(),
                eventCategory: "meta",
            });
            void sendVisitMeta({
                event: "formTitleSource",
                eventValue: getTitleSource(),
                eventCategory: "meta",
            });
        }
    }, []);

    useEffect(() => {
        if (index === 0 && window.innerWidth > 767) {
            const selector =
                "input[type=text], input[type=tel], input[type=number], input[type=date],input[type=email], textarea";

            if (
                document
                    .getElementById(`form_${form.id}`)
                    ?.querySelectorAll(selector).length
            ) {
                setTimeout(() => {
                    const element = document
                        .getElementById(`form_${form.id}`)
                        ?.querySelectorAll(selector);

                    if (element && element.length) {
                        (element[0] as HTMLInputElement).focus();
                    }
                }, 500);
            }
        }
    }, [index]);

    // excludedFields = list of fields that should not displayed in the form step
    const excludedFields: string[] = useMemo(() => {
        const excludedFieldsArray: string[] = [];
        step.fields.forEach((field) => {
            const metaKeys: string[] = field.meta
                ? Object.keys(field.meta)
                : [];

            // check if field has expandable meta key
            const isExpandable =
                Array.isArray(metaKeys) && metaKeys.length
                    ? metaKeys[0] === "expandable"
                        ? true
                        : false
                    : false;
            if (isExpandable) {
                const expandableFields = JSON.parse(
                    field.meta?.expandable as string,
                ) as ExpandableFieldMeta;
                const fieldsCodeName = Object.keys(expandableFields);
                for (let i = 0; i < fieldsCodeName.length; i++) {
                    if (
                        expandableFields[fieldsCodeName[i]].ignoreField ===
                        "true"
                    ) {
                        excludedFieldsArray.push(fieldsCodeName[i]);
                    }
                }
            }
        });
        return excludedFieldsArray;
    }, [step.fields]);

    const fieldsList = [];
    const sharedFieldsList = [];
    const fieldsListLength = step.fields.length;
    for (let i = 0; i < fieldsListLength; i++) {
        if (!excludedFields.includes(step.fields[i].codeName)) {
            if (
                step.fields[i].tag === "common" ||
                step.fields[i].tag === "pii"
            ) {
                if (SHARED_FIELDS_CODENAME.includes(step.fields[i].codeName)) {
                    sharedFieldsList.push(
                        <div
                            style={
                                COMMON_FIELDS_CODENAME.includes(
                                    step.fields[i].codeName,
                                )
                                    ? { width: "100%" }
                                    : {}
                            }
                            key={i}
                        >
                            <FormField
                                key={i}
                                field={step.fields[i]}
                                fields={step.fields}
                                asTitle={
                                    step.fields.length === 1 && !step.title
                                }
                                {...props}
                                handleFieldBlur={handleFieldBlur}
                                handleFieldChange={handleFieldChange}
                                fieldsData={fieldsData}
                                visitedSteps={visitedSteps}
                                currentStep={currentStep}
                                colors={colors}
                                isLoading={isLoading}
                                isLastStep={isLastStep}
                                formSubmitted={formSubmitted}
                                stepStatus={stepStatus}
                                formId={formId}
                                form={form}
                                isSecondServiceForm={
                                    isSecondServiceForm as boolean
                                }
                                searchableMultiSelect={searchableMultiSelect}
                            />
                        </div>,
                    );
                } else {
                    sharedFieldsList.push(
                        <div style={{ width: "100%" }} key={i}>
                            <FormField
                                key={i}
                                field={step.fields[i]}
                                fields={step.fields}
                                asTitle={
                                    step.fields.length === 1 && !step.title
                                }
                                {...props}
                                handleFieldBlur={handleFieldBlur}
                                handleFieldChange={handleFieldChange}
                                fieldsData={fieldsData}
                                visitedSteps={visitedSteps}
                                currentStep={currentStep}
                                colors={colors}
                                isLoading={isLoading}
                                isLastStep={isLastStep}
                                formSubmitted={formSubmitted}
                                stepStatus={stepStatus}
                                formId={formId}
                                form={form}
                                isSecondServiceForm={
                                    isSecondServiceForm as boolean
                                }
                                searchableMultiSelect={searchableMultiSelect}
                            />
                        </div>,
                    );
                }
            } else {
                if (
                    SHARED_FIELDS_CODENAME.includes(step.fields[i].codeName) &&
                    !isPiiCompletionForm &&
                    !props.goToListPage
                ) {
                    sharedFieldsList.push(
                        <div
                            style={
                                COMMON_FIELDS_CODENAME.includes(
                                    step.fields[i].codeName,
                                )
                                    ? {
                                          width: "100%",
                                          ...(form.testConfig?.config
                                              ?.mergeFirstInput
                                              ? {
                                                    display: "flex",
                                                    alignItems: "center",
                                                    ...(width <= 767
                                                        ? {
                                                              flexDirection:
                                                                  "column",
                                                          }
                                                        : {}),
                                                }
                                              : {}),
                                      }
                                    : {}
                            }
                            className={
                                step.fields[i].fieldType === "hidden"
                                    ? "hidden"
                                    : ""
                            }
                            key={i}
                        >
                            <FormField
                                key={i}
                                field={step.fields[i]}
                                fields={step.fields}
                                asTitle={
                                    step.fields.length === 1 && !step.title
                                }
                                {...props}
                                handleFieldBlur={handleFieldBlur}
                                handleFieldChange={handleFieldChange}
                                fieldsData={fieldsData}
                                visitedSteps={visitedSteps}
                                currentStep={currentStep}
                                colors={colors}
                                isLoading={isLoading}
                                isLastStep={isLastStep}
                                formSubmitted={formSubmitted}
                                stepStatus={stepStatus}
                                formId={formId}
                                form={form}
                                isSecondServiceForm={
                                    isSecondServiceForm as boolean
                                }
                                searchableMultiSelect={searchableMultiSelect}
                            />
                            {showMergedButton()}
                        </div>,
                    );
                } else {
                    if (
                        !piiCompletionData?.missingFields?.includes(
                            step.fields[i].codeName,
                        )
                    ) {
                        fieldsList.push(
                            <FormField
                                key={i}
                                field={step.fields[i]}
                                fields={step.fields}
                                asTitle={
                                    step.fields.length === 1 && !step.title
                                }
                                {...props}
                                handleFieldBlur={handleFieldBlur}
                                handleFieldChange={handleFieldChange}
                                fieldsData={fieldsData}
                                visitedSteps={visitedSteps}
                                currentStep={currentStep}
                                colors={colors}
                                isLoading={isLoading}
                                isLastStep={isLastStep}
                                formSubmitted={formSubmitted}
                                stepStatus={stepStatus}
                                formId={formId}
                                form={form}
                                isSecondServiceForm={
                                    isSecondServiceForm as boolean
                                }
                                searchableMultiSelect={searchableMultiSelect}
                            />,
                        );
                    }
                }
            }
        }
    }
    const getFieldsGroup = (fieldsList: JSX.Element[]) => {
        const groupByTag: { [x: string]: JSX.Element[] } = {};
        fieldsList.forEach((field) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (!groupByTag[field?.props?.field?.tag as string]) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                groupByTag[field?.props?.field?.tag as string] = [];
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            groupByTag[field?.props?.field?.tag as string].push(field);
        });
        const tagsElements = Object.keys(tags);

        return tagsElements.map((tag) => {
            const categoryIcon = getSecondServiceCategoryIcon(
                tag,
                category as Category,
                form,
            ) as Image;

            return (
                <div key={tag}>
                    {tag !== "common" && (
                        <div
                            className={`${styles.serviceLabel} ${
                                tags[tag].active ? styles.activeService : ""
                            } flex items-center `}
                            onClick={() => {
                                if (updateSecondServices)
                                    updateSecondServices(tag);
                            }}
                        >
                            {categoryIcon && (
                                <div className="relative">
                                    <S3Icon
                                        image={categoryIcon}
                                        svgClassName={styles["category-icon"]}
                                    />
                                </div>
                            )}
                            <span>
                                {tag === "undefined" ? form.categoryName : tag}
                            </span>
                        </div>
                    )}
                    {tags[tag].active && (
                        <div className={styles["tagGroup"]}>
                            {groupByTag[tag]
                                ? groupByTag[tag].map((f) => f)
                                : groupByTag["undefined"]
                                ? groupByTag["undefined"].map((f) => f)
                                : []}
                        </div>
                    )}
                </div>
            );
        });
    };
    const extendAnswerSummaryConfig = answerSummaryConfigs?.find((config) => {
        return config.extend;
    });

    const answerSummaryConfig =
        answerSummaryConfigs?.find((config) => config.stepIndex === index) ??
        (extendAnswerSummaryConfig &&
        currentStep > extendAnswerSummaryConfig.stepIndex
            ? extendAnswerSummaryConfig
            : undefined);

    return (
        <div
            className={`${styles["step"]} ${classes?.stepClassName ?? ""} ${
                index === 0 && !step.description && step.title
                    ? (classes?.firstStepClass as string)
                    : ""
            }`}
        >
            {currentStep === index &&
                !formErrors &&
                operation?.key !== "ROUND_TWO" && (
                    <>
                        {answerSummaryConfig && (
                            <AnswerSummary
                                answerSummaryConfig={answerSummaryConfig}
                                fieldsState={fieldsData}
                            />
                        )}
                        {step.tag &&
                            !["pii", "common"].includes(step.tag) &&
                            !isSecondServiceForm && (
                                <div
                                    className={classes?.stepTagClassName ?? ""}
                                >
                                    {step.tag}
                                </div>
                            )}
                        {(step.title || customMainTitle) && !hideStepTitle ? (
                            index === 0 ? (
                                <>
                                    <h1
                                        className={`${styles["title"]} ${
                                            classes?.stepTitleClassName ?? ""
                                        }`}
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: getTitle(),
                                            }}
                                        ></span>
                                        {step.tooltip && (
                                            <Tooltip
                                                placement="bottom"
                                                overlay={
                                                    <span>{step.tooltip}</span>
                                                }
                                                trigger={["hover"]}
                                                overlayClassName={
                                                    styles["tooltip"]
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles["tooltip-icon"]
                                                    }
                                                >
                                                    <TooltipIcon />
                                                </div>
                                            </Tooltip>
                                        )}
                                    </h1>
                                </>
                            ) : (
                                <h2
                                    className={`${styles["title"]} ${
                                        classes?.stepTitleClassName ?? ""
                                    }`}
                                >
                                    {isFormInsideOffersModal &&
                                    textBlocks.find(
                                        (item) =>
                                            item.position ===
                                            "form-inside-exit-modal-second-step-title",
                                    )?.text ? (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: replacePlaceholders(
                                                    textBlocks.find(
                                                        (item) =>
                                                            item.position ===
                                                            "form-inside-exit-modal-second-step-title",
                                                    )?.text as string,
                                                    {
                                                        category:
                                                            category?.name,
                                                        region: visitDetails.region,
                                                        city: visitDetails?.city,
                                                    },
                                                ),
                                            }}
                                        />
                                    ) : (
                                        <span>{step.title}</span>
                                    )}
                                    {step.tooltip && (
                                        <Tooltip
                                            placement="bottom"
                                            overlay={
                                                <span>{step.tooltip}</span>
                                            }
                                            trigger={["hover"]}
                                            overlayClassName={styles["tooltip"]}
                                        >
                                            <div
                                                className={
                                                    styles["tooltip-icon"]
                                                }
                                            >
                                                <TooltipIcon />
                                            </div>
                                        </Tooltip>
                                    )}
                                </h2>
                            )
                        ) : null}

                        {step.description &&
                            !hideStepDescription &&
                            !isFormInsideOffersModal && (
                                <div
                                    className={`${styles["description"]} ${
                                        classes?.stepDescriptionClassName ?? ""
                                    } ${
                                        hideStepTitle
                                            ? `${styles["title"]} ${
                                                  classes?.stepTitleClassName ??
                                                  ""
                                              }`
                                            : ""
                                    }`}
                                >
                                    {getDescription()}
                                </div>
                            )}
                        {isPiiCompletionForm ? (
                            <>
                                <div
                                    className={`pii-completion-missing-fields`}
                                >
                                    <MissingFieldsHeadline />
                                    {piiCompletionData?.missingFields?.map(
                                        (field) => (
                                            <div key={field}>
                                                <FormField
                                                    field={
                                                        step.fields.find(
                                                            (i) =>
                                                                i.codeName ===
                                                                field,
                                                        ) as Field
                                                    }
                                                    fields={step.fields}
                                                    asTitle={
                                                        step.fields.length ===
                                                            1 && !step.title
                                                    }
                                                    {...props}
                                                    handleFieldBlur={
                                                        handleFieldBlur
                                                    }
                                                    handleFieldChange={
                                                        handleFieldChange
                                                    }
                                                    fieldsData={fieldsData}
                                                    visitedSteps={visitedSteps}
                                                    currentStep={currentStep}
                                                    colors={colors}
                                                    isLoading={isLoading}
                                                    isLastStep={isLastStep}
                                                    formSubmitted={
                                                        formSubmitted
                                                    }
                                                    stepStatus={stepStatus}
                                                    formId={formId}
                                                    form={form}
                                                    isSecondServiceForm={false}
                                                    searchableMultiSelect={
                                                        searchableMultiSelect
                                                    }
                                                />
                                            </div>
                                        ),
                                    )}

                                    {!showPiiCompletionOtherFields && (
                                        <div
                                            onClick={() => {
                                                setShowPiiCompletionOtherFields(
                                                    true,
                                                );
                                            }}
                                            className="text-[#848484] font-semibold text-center cursor-pointer mb-4"
                                        >
                                            <span className="text-[#328df8]">
                                                Edit
                                            </span>{" "}
                                            Your Answers
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className={`${
                                        isSecondServiceForm
                                            ? styles["serviceListWrapper"]
                                            : ""
                                    } ${styles["fields"]} ${
                                        classes?.fieldsWrapperClassName ?? ""
                                    }`}
                                >
                                    {isSecondServiceForm
                                        ? getFieldsGroup(fieldsList)
                                        : fieldsList}
                                </div>

                                <div
                                    className={`
                                ${
                                    isSecondServiceForm
                                        ? styles["sharedFieldsContainer"]
                                        : ""
                                }
                                ${
                                    isSecondServiceForm &&
                                    !isSecondServiceSelected
                                        ? "hidden"
                                        : ""
                                }
                                    `}
                                >
                                    <div
                                        className={`${styles["fields"]} ${
                                            classes?.fieldsWrapperClassName ??
                                            ""
                                        } ${
                                            isSecondServiceForm
                                                ? `${styles["sharedFieldsWrapper"]} flex flex-wrap`
                                                : ""
                                        }`}
                                    >
                                        {sharedFieldsList}
                                    </div>
                                </div>
                            </>
                        )}

                        {isLastStep && !isSecondServiceForm ? (
                            <SmsTCPA />
                        ) : null}
                        <div className="flex flex-col md:gap-3  sm:flex-row justify-between items-center">
                            {isSecondServiceForm ? (
                                <div
                                    className={`${styles["buttons"]} order-2 sm:order-1`}
                                >
                                    <button
                                        className={` ${
                                            styles["submit-button"]
                                        } ${styles["form-button"]} ${
                                            formButtonClassName ?? ""
                                        }`}
                                        style={{
                                            border: `1px solid ${
                                                colors.primaryColor ||
                                                "var(--global-form-buttons-background-color)"
                                            }`,
                                            backgroundColor: "#fff",
                                            color: "#000",
                                        }}
                                        type="button"
                                        onClick={() => {
                                            setSkipToThankYou(true);
                                            if (isSecondServiceForm) {
                                                setButtonDisabled({
                                                    noThankYou: false,
                                                    getQuote: true,
                                                });
                                            }
                                        }}
                                        disabled={isLoading}
                                    >
                                        No Thank You
                                    </button>
                                </div>
                            ) : (
                                <></>
                            )}

                            <div
                                className={`${styles["buttons"]} order-1 sm:order-2`}
                            >
                                {isPiiCompletionForm &&
                                    showPiiCompletionOtherFields && (
                                        <>
                                            <div className="flex flex-col">
                                                <OtherFieldsHeadline />

                                                <div
                                                    className={`${
                                                        styles["fields"]
                                                    } ${
                                                        classes?.fieldsWrapperClassName ??
                                                        ""
                                                    } `}
                                                >
                                                    {fieldsList}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                {checkIfShowNextButton()}
                                {/* {showLogos &&
                                    operation?.firstRoundBuyers?.map(
                                        (buyer) => (
                                            <div
                                                key={buyer.buyerId}
                                                className="my-2 mx-auto flex items-center justify-center"
                                            >
                                                {buyer.logo && (
                                                    <>
                                                        <img
                                                            src={buyer.logo}
                                                            alt={
                                                                buyer.displayName
                                                            }
                                                            className={`w-20 sm:w-24  ${styles["buyer-logo"]}`}
                                                        />
                                                        {buyer.displayName && (
                                                            <span
                                                                className={
                                                                    "border-l-2 border-[#e5e5e5] h-8 mx-4"
                                                                }
                                                            ></span>
                                                        )}
                                                    </>
                                                )}
                                                <span className="text-sm text-[#484848]">
                                                    {buyer.displayName}
                                                </span>
                                            </div>
                                        ),
                                    )} */}
                            </div>
                        </div>

                        {formError && (
                            <div className={styles["form-error"]}>
                                <span>{formError}</span>
                            </div>
                        )}
                    </>
                )}
        </div>
    );
};
